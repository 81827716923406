export const getViewport = () => {
  if (window.visualViewport && /Android/.test(navigator.userAgent)) {
      // https://developers.google.com/web/updates/2017/09/visual-viewport-api    Note on desktop Chrome the viewport subtracts scrollbar widths so is not same as window.innerWidth/innerHeight
      return {
          left: visualViewport.pageLeft,
          top: visualViewport.pageTop,
          width: visualViewport.width,
          height: visualViewport.height
      };
  }
  var viewport = {
          left: window.pageXOffset,   // http://www.quirksmode.org/mobile/tableViewport.html
          top: window.pageYOffset,
          width: window.innerWidth || document.documentElement.clientWidth,
          height: window.innerHeight || document.documentElement.clientHeight
  };
  if (/iPod|iPhone|iPad/.test(navigator.platform) && isInput(document.activeElement)) {       // iOS *lies* about viewport size when keyboard is visible. See http://stackoverflow.com/questions/2593139/ipad-web-app-detect-virtual-keyboard-using-javascript-in-safari Input focus/blur can indicate, also scrollTop: 
      return {
          left: viewport.left,
          top: viewport.top,
          width: viewport.width,
          height: viewport.height * (viewport.height > viewport.width ? 0.66 : 0.45)  // Fudge factor to allow for keyboard on iPad
      };
  }
  return viewport;
}


function isInput(el) {
  var tagName = el && el.tagName && el.tagName.toLowerCase();
  return (tagName == 'input' && el.type != 'button' && el.type != 'radio' && el.type != 'checkbox') || (tagName == 'textarea');
}
