import getFoodAdvice from '../lib/food.js'
import weekData from '../lib/weekdata.json'

const weekMap = {}
weekData.forEach(d => {
  weekMap[d.week] = d
})

const WELCOME = [
  'Hey girl! You hungry?', 'How are you?', "What's tasty?"
]

const TRIMESTER_WELCOME = {
  1: [...WELCOME, "Feeling ok or rather...🤢?"],
  2: [...WELCOME],
  3: [...WELCOME],
}

const IDK = [
  '🤷‍♀️', 'haha, no idea', 'idk', 'sorry lol', 'dunno!'
]
const GOOD = [
  '😋', '😍', 'tasty!', 'yummy!!'
]
const BAD = [
  '😫', '😩', '😖', '😣', '😱', '🥵', '😟', '😕', 'urghs', 'nonononononoo'
]
const NEUTRAL = ['totally ok', '🙂', '😌', '👍']

const DAY = 24 * 60 * 60 * 1000
const DAYS_IN_WEEK = 7
const WEEK = DAYS_IN_WEEK * DAY
const PREGNANCY_DURATION = 40 * WEEK

function getWeekDate (dueDateStr, week) {
  const dueDate = new Date(dueDateStr).getTime()
  const start = dueDate - PREGNANCY_DURATION
  const date = new Date(start + week * WEEK)
  return Intl.DateTimeFormat(undefined, {dateStyle: "long"}).format(date)
}

function getPregnancyProgress (dueDateStr, date) {
  if (!date) {
    date = new Date()
  }
  date = date.getTime()
  const dueDate = new Date(dueDateStr).getTime()
  const start = dueDate - PREGNANCY_DURATION
  const diff = date - start
  const numDays = Math.floor(diff / DAY)
  const weeks = Math.floor(numDays / DAYS_IN_WEEK)
  const dayOffset = numDays % DAYS_IN_WEEK
  const weekNumber = weeks + 1
  const percentage = Math.round(diff / PREGNANCY_DURATION * 100)
  const trimester = weekNumber < 13 ? 1 : weekNumber <= 27 ? 2 : 3;
  return {
    weeks,
    weekNumber,
    start,
    numDays,
    dayOffset,
    percentage,
    trimester,
  }
}

function sayIdk(hintSetting) {
  const result = [{
    content: `${randElement(IDK)}`
  }]
  if (hintSetting) {
    result.push({
      content: 'Please enter info in the settings! ↗️ ⚙️'
    })
  } else {
    result.push({
      content: `Ask me things like 'week?', or 'week 14' or 'salmon?'`
    })
  }
  return result
}

const MODEL = [
  {
    test: /^(hello|hi|hey|hallo)\b/i,
    action: [
      {content: 'Hello baby mama!'},
      {content: "heyho!"},
      {content: '🤗'},
    ]
  },
  {
    test: /(\d{1,2})\.(\d{1,2})\.(\d{2,4})/i,
    action (match, settings) {
      if (!settings || settings.dueDate === null || settings.dueDate === undefined) {
        return sayIdk(true)
      }
      const day = parseInt(match[1])
      const month = parseInt(match[2])
      let year = parseInt(match[3])
      if (year < 100) {
        year += 2000
      }
      const date = new Date(year, month - 1, day)
      const pregancyInfo = getPregnancyProgress(settings.dueDate, date)
      if (pregancyInfo.weekNumber > 42) {
        return [{
          content: `You will likely not be pregnant on ${day}.${month}.${year}.`
        }]
      } else if (pregancyInfo.weekNumber < 1) {
        return [{
          content: `You were likely not pregnant on ${day}.${month}.${year}.`
        }]
      }
      return [{
        content: `On ${day}.${month}.${year} you will be in week ${pregancyInfo.weekNumber} and trimester ${pregancyInfo.trimester} (${pregancyInfo.weeks}+${pregancyInfo.dayOffset}).`
      }]
    }
  },
  {
    test: /week (\d+)/i,
    action (match, settings) {
      if (!settings || settings.dueDate === null || settings.dueDate === undefined) {
        return sayIdk(true)
      }
      const week = parseInt(match[1])
      if (week > 42) {
        return [{
          content: `oh oh`
        }]
      }
      return [{
        content: `Week ${week} starts on ${getWeekDate(settings.dueDate, week)}.`
      }]
    }
  },
  {
    test: /.*\b(how far along|week|time)\b.*/i,
    action (message, settings) {
      if (!settings || settings.dueDate === null || settings.dueDate === undefined) {
        return sayIdk(true)
      }
      const pregancyInfo = getPregnancyProgress(settings.dueDate)
      const weekInfo = weekMap[pregancyInfo.weeks]
      let extraContent = []
      if (weekInfo) {
        if (weekInfo.fruit) {
          extraContent.push({
            content: `It's a ${weekInfo.fruit} at ${weekInfo.size}!`
          })
        }
        if (weekInfo.description) {
          extraContent.push({
            content: weekInfo.description
          })
        }
      }
      return [{
        content: `You're in week ${pregancyInfo.weekNumber} and trimester ${pregancyInfo.trimester}! Today: ${pregancyInfo.weeks}+${pregancyInfo.dayOffset}!`
      },
      ...extraContent]
    }
  },
  {
    test (message) {
      const advice = getFoodAdvice(message)
      if (advice.length === 0) {
        return null
      }
      return advice
    },
    action (advice) {
      let result = []
      for (let a of advice) {
        let message
        let klass
        if (a.score > 0) {
          message = randElement(GOOD)
          klass = 'primary'
        } else if (a.score < 0) {
          message = randElement(BAD)
          klass = 'accent'
        } else {
          klass = 'info'
          message = randElement(NEUTRAL)
        }
        result.push({
          content: `${a.emoji ? a.name + ' ' + a.emoji : a.name}? ${message}${a.caveat ? ' but: ' + a.caveat : ''}`,
          detail: a.description,
          klass: klass,
        })
      }
      return result
    }
  }
]

const randElement = (items) => {
  return items[Math.floor(Math.random() * items.length)];
}

export default {
  startUp: (settings) => {
    if (settings.dueDate) {
      const pregancyInfo = getPregnancyProgress(settings.dueDate)
      return randElement(TRIMESTER_WELCOME[pregancyInfo.trimester] || WELCOME)
    }
    return randElement(WELCOME)
  },
  message: (message, settings) => {
    let result = null
    for (const rule of MODEL) {
      let match
      if (rule.test instanceof RegExp) {
        match = rule.test.exec(message)
      } else {
        match = rule.test(message, settings)
      }
      if (match) {
        if (rule.action instanceof Array) {
          result = [randElement(rule.action)]
        } else {
          result = rule.action(match, settings)
        }
        break
      }
    }
    if (result) {
      return result
    }
    return sayIdk()
  }
}