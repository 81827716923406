/* eslint-disable no-console */
import runtime from 'serviceworker-webpack-plugin/lib/runtime'
import registerEvents from 'serviceworker-webpack-plugin/lib/browser/registerEvents'

export default function registerServiceWorker (callbacks) {
  if ('serviceWorker' in navigator) {
    const registration = runtime.register()

    registerEvents(registration, {
      onInstalled: () => {
        console.log('onInstalled')
      },
      onUpdateReady: () => {
        console.log('onUpdateReady')
        if (callbacks.onUpdateReady) {
          callbacks.onUpdateReady(registration)
        }
      },

      onUpdating: () => {
        console.log('onUpdating')
      },
      onUpdateFailed: () => {
        console.log('onUpdateFailed')
      },
      onUpdated: () => {
        console.log('onUpdated')
      }
    })
    return registration
  }
  return Promise.resolve(null)
}
