import '@mdi/font/css/materialdesignicons.css'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify, {
  iconfont: 'mdi'
})

// https://coolors.co/ffffff-84dcc6-ebefbf-ffa69e-ff686b

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#84dcc6',
        secondary: '#ebefbf',
        accent: '#ffa69e',
        error: '#ff686b',
        info: '#6dcbde',
        success: '#5fb6cc',
        warning: '#ffc459'
      },
      dark: {
        primary: '#288ca0',
        secondary: '#abb829',
        accent: '#b14c43',
        error: '#a82225',
        info: '#338b9d',
        success: '#5fb6cc',
        warning: '#ffc459'
      },
    }
  }
})
