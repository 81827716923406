<template>
  <v-container fluid style="padding-bottom: 0; height: 99%">
    <div class="message-container" ref="messageContainer" >
      <message v-for="message in messages" :key="message.timestamp" :message="message"></message>
      <div class="typing info" v-if="typing">. . .</div>
    </div>
    <div class="message-input">
      <form method="post" action="" @submit.prevent="sendMessage">
        <v-text-field
          v-model="content"
          autofocus
          autocomplete="off"
          enterkeyhint="send"
          append-outer-icon="mdi-send"
          @focus="focusInput"
          @blur="blurInput"
          @click:append-outer="sendMessage"
          @keyup.enter="sendMessage"
        >
          <template v-slot:append-outer>
            <button type="submit" aria-label="append icon"
              class="v-icon notranslate v-icon--link mdi mdi-send">
            </button>
          </template>
        </v-text-field>
      </form>
    </div>
  </v-container>
</template>


<script>

import Message from './Message.vue'
import bot from '../lib/bot.js'
import {getViewport} from '../lib/utils.js'

const MESSAGE_KEY = 'strawberryMessages'
const TOOLBAR_HEIGHT = 56 + 12


export default {
  name: 'Chat',
  components: {
    Message,
  },
  props: {
    settings: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      content: '',
      typing: false,
      messages: JSON.parse(localStorage.getItem(MESSAGE_KEY)) || []
    }
  },
  mounted () {
    this.scrollToEnd()
    this.postWelcomeMessage()
    const callResize = (e) => this.resize(e.target)
    if (window.visualViewport) {
      console.log("set event listeners")
      // window.visualViewport.addEventListener('scroll', callResize);
      window.visualViewport.addEventListener('resize', callResize);
    }
    // this.resize(window)
  },
  methods: {
    resize () {
      const viewport = getViewport()
      // const viewport = window.visualViewport;
      console.log('viewport.height', viewport.height)
      const availableHeight = viewport.height - TOOLBAR_HEIGHT
      console.log('resizing', availableHeight)
      console.log(this, this.$refs.messageContainer)
      // this.$refs.messageContainer.style.height = availableHeight + "px"
    },
    focusInput (e) {
      console.log("focus", e)
      console.log(window.visualViewport.height)
    },
    blurInput (e) {
      console.log("blur", e)
      console.log(window.visualViewport.height)
    },
    postWelcomeMessage () {
      this.postMessages([{
        content: bot.startUp(this.settings)
      }], new Date().getTime())
    },
    sendMessage (e) {
      e.preventDefault()
      if (this.content !== '') {
        let time = parseInt(new Date().getTime(), 10)
        this.messages = [
          ...this.messages,
          {
          content: this.content,
          me: true,
          timestamp: time
        }]
        let advice = this.makeResponse(this.content, time)
        this.content = ''
        this.postMessages(advice)
      }
    },
    postMessages (messages) {
      this.typing = true
      this.scrollToEnd()
      window.setTimeout(() => {
        this.messages = [
          ...this.messages,
          ...messages
        ]
        this.typing = false
        this.saveMessages()
        this.scrollToEnd()
      }, 1500 * Math.random())
    },
    makeResponse (message, time) {
      let results = bot.message(message, this.settings)
      return results.map((m, i) => ({
        ...m,
        timestamp: time + i + 1
      }))
    },
    saveMessages () {
      localStorage.setItem(MESSAGE_KEY, JSON.stringify(this.messages))
    },
    scrollToEnd () {
      this.$nextTick(() => {
        var container = this.$refs.messageContainer
        container.lastElementChild.scrollIntoView({ behavior: "smooth", block: "center" })
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .message-container {
    /* flex: 1 1 auto; */
    /* overflow-y: auto; */
    /* align-self: center; */
    height: calc(100% - 56px - 12px);
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
  }
  .message-input {
    position: sticky;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(2px);
  }
  .typing {
    padding: 5px 8px;
    border-radius: 5px;
    display: inline-block;
    align-self: flex-start;
  }
</style>
