<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      fixed
    >
      <div style="width:48px;margin-right:-12px"></div>
      <v-spacer></v-spacer>

      <v-toolbar-title>
        🍓
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click.stop="showSettings = true">
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main>
      <Chat :settings="settings"/>

      <v-dialog
        v-model="showSettings"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
        <v-card>
          <v-card-title class="headline">Settings</v-card-title>

          <v-card-text>
            <v-text-field type="date" label="Due date"
              v-model="dueDate"
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="green darken-1"
              text
              @click="saveSettings"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        :value="hasSnack"
        bottom
        :timeout="0"
        absolute
        >
        <template v-if="updateReady">
          Update available! <v-btn color="green" @click="startUpdate">Update now</v-btn>
        </template>
      </v-snackbar>
    </v-main>

  </v-app>
</template>

<script>
import Chat from './components/Chat.vue';

import registerServiceWorker from './registerserviceworker.js'
import applyUpdate from 'serviceworker-webpack-plugin/lib/browser/applyUpdate'

const SETTINGS_KEY = 'strawberrySettings'

export default {
  name: 'App',

  components: {
    Chat,
  },
  data () {
    return {
      showSettings: false,
      updateReady: false,
      refreshing: false,
      registration: null,
      settings: JSON.parse(localStorage.getItem(SETTINGS_KEY)) || {}
    }
  },
  created () {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        // Here the actual reload of the page occurs
        window.location.reload()
      })
    }
  },
  mounted () {
    console.log('mounted', process.env.NODE_ENV)
    this.initDarkMode();
    let self = this

    registerServiceWorker({
      onUpdateReady: () => {
        self.updateReady = true
      }
    })
  },
  computed: {
    hasSnack () {
      return this.updateReady
    },
    dueDate: {
      get () {
        if (!this.settings.dueDate) {
          return null
        }
        return new Date(this.settings.dueDate).toISOString().split('T')[0]
      },
      set (val) {
        let d = new Date(val)
        if (d.toString() === 'Invalid Date') {
          this.settings.dueDate = null  
        }
        this.settings.dueDate = d.getTime()
      }
    }
  },
  methods: {
    saveSettings () {
      localStorage.setItem(SETTINGS_KEY, JSON.stringify(this.settings))
      this.showSettings = false
    },
    startUpdate () {
      this.updateReady = false
      applyUpdate().then(() => {
        window.location.reload()
      })
    },
    initDarkMode() {
      const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      darkMediaQuery.addEventListener('change', () => {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      });

      if (darkMediaQuery.matches) {
        console.log('change default light to dark theme');
        // need to set 0 sec timeout to set the dark more after mounted event, due to some bug in the framework
        setTimeout(() => this.$vuetify.theme.dark = true, 0);
      }
    },
  }
};
</script>
