import foodData from './fooddata.json'

const NON_WORDS = /[^\w ]/g

const foodIndex = {}
foodData.forEach((d, i) => {
  foodIndex[d.name.replace(NON_WORDS, '').toLowerCase()] = i
  foodIndex[d.emoji] = i;
  ((d.tokens || '').split(',') || []).forEach(t => {
    t = t.replace(NON_WORDS, '').toLowerCase().trim()
    if (t.length > 0) {
      foodIndex[t] = i
    }
  })
})

console.log(foodIndex)

function getFoodAdvice (message) {
  message = message.toLowerCase()
  let tokens = message.replace(NON_WORDS, '').split(' ')
  console.log(tokens)
  let results = []
  let fullMessage = tokens.join(' ')
  let match = foodIndex[fullMessage]
  console.log(fullMessage)
  if (match) {
    results.push(foodData[match])
  }
  for (let token of tokens) {
    if (token === fullMessage) {
      continue
    }
    let match = foodIndex[token]
    if (match !== undefined) {
      results.push(foodData[match])
    }
  }
  return results
}


export default getFoodAdvice