<template>
  <div class="message" :class="{own: message.me, [messageClass]: true}">
    <div class="content">
      {{ message.content }}
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      return {}
    },
    props: [
      'message'
    ],
    computed: {
      messageClass () {
        if (this.message.me) {
          return 'secondary'
        }
        return this.message.klass || 'info'
      }
    }
  }
</script>

<style>
  .message {
    flex: 0 1 auto;
    align-self: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: left;
    max-width: 60%;
    border-radius: 5px;
    justify-self: self-end;
  }
  .message.own  {
    align-self: flex-end;
    text-align: right;
  }
  .message .content {
    padding: 5px 8px;
    display: inline-block;
  }

</style>